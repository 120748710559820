<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group
        id="input-group-name"
        label="Nama:"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Ruangan"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

     
      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description">Alamat: <em class="text-muted">opsional</em></label>
        <b-form-textarea
          id="input-description"
          v-model="form.address"
          placeholder="Alamat"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.address }}</small>
      </b-form-group>

       <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description">Keterangan: <em class="text-muted">opsional</em></label>
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Keterangan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <div class="row">
        <div class="col-md-12 mb-2" >
          <h5><b>Tambah Rak</b></h5>
        </div>
      </div>

      <!-- Input Rak -->
        <b-form-group id="input-group-batch-no">
          <div class="row">
            <div class="col-md-3">
              <b-form-input
                id="input-batch-no"
                v-model="rack_form.name"
                placeholder="Nama / No Rak"
              >
              </b-form-input>
              <small class="text-danger">{{ error_rack.name }}</small>
            </div>
            <div class="col-md-1">
              <b-button
                size="sm"
                class="btn-info"
                v-b-tooltip.hover
                title="Tambah Batch"
                type="button"
                @click="setRack"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
            </div>
          </div>
        </b-form-group>

      <div class="bg-light">
        <table class="table">
          <tr>
            <th>#</th>
            <th>Nama / No Rak</th>
            <th>Aksi</th>
          </tr>
          <tr v-for="btc in data_rack" :key="btc.id">
            <td>{{ btc.id }}</td>
            <td>{{ btc.name }}</td>
            <td>
              <b-button
                size="sm"
                class="btn-danger"
                v-b-tooltip.hover
                title="Hapus"
                type="button"
                v-if="purpose == 'add'"
                @click="removeRack(btc.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
              <b-button
                size="sm"
                class="btn-danger"
                v-b-tooltip.hover
                title="Hapus"
                type="button"
                v-if="purpose == 'edit'"
                @click="removeEditRack(btc.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </td>
          </tr>
        </table>
      </div>

      <!-- Submit & Cancel button -->
      <b-button
        type="submit"
        variant="primary"
      >Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/warehouses')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css"

export default {
  props: {
    form: Object,
    purpose: String,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        address:"",
        description: "",
      },
      error_rack:{
        name:"",
      },
      // data
      data_rack:[],
      rack_form:{
        id:"",
        name:"",
      },
    


    };
  },

  methods: {
    // async getPolyclinics() {
    //   let response = await module.setSelectOption('polyclinics')
    //   if (response.state == 'success') {
    //     this.polyclinics = response.data
    //     this.polyclinics.push({ text: "Pilih Poliklinik", value: null, disabled: true })
    //   }
    // },

    async setRack() {
    //  console.log(this.rack_form.name);

      if(this.purpose == "add"){
        if (this.rack_form.name == "") {
            this.error_rack.name = "Nama tidak boleh kosong";
        } else {
          this.rack_form.id = this.data_rack.length+1
          this.error_rack.name = "";
          let clone = {...this.rack_form} ;
          this.data_rack.push(clone);
        }
      }else{
        this.rack_form.id = this.$route.params.id
        let response = await module.submit(this.rack_form, "warehouses-rack" );

        if (response) {
          this.getRack()
        }
      }

      this.rack_form.name = ""
    },

    

    removeRack($id) {
      let a;
      for (a = 0; a < this.data_rack.length; a++) {
        if (this.data_rack[a].id == $id) {

          this.data_rack.splice(a, 1);
        }
      }
    },
    
    async removeEditRack(id) {
      let result = await module.delete("warehouse-rack/delete/" + id);
      if (result) {
        this.getRack()
      }
    },

    async getRack() {
      this.data_rack = await module.get('warehouses-rack/' + this.$route.params.id)
    },

    async formOnsubmit() {
      // merge data
      let rack = JSON.stringify(this.data_rack)
      this.form.rack = rack 

      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/masters/warehouses");
      }
    },
  },
  mounted() {
 //   this.getBuildingOptions()
    if(this.purpose == "edit"){
      this.getRack()
    }
  }
};
</script>

<style>
</style>